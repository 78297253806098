:root {
	--grid-columns-s: 4;
	--grid-columns: 10;
	--grid-gap: 0;
	--grid-space-s: calc((100vw - 60px) / var(--grid-columns-s));
	--grid-space-m: calc((100vw - 60px) / (var(--grid-columns) + 2));
	--grid-space-l: calc(1440px / (var(--grid-columns) + 2));
	--grid-space-and-gap: calc(var(--grid-space) + var(--grid-gap));
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
@import '../node_modules/modern-normalize/modern-normalize.css';

@font-face {
	font-family: 'DINCondensed';
	src: url('/fonts/DINCondensedBold.eot');
	src: url('/fonts/DINCondensedBold.woff2') format('woff2'), url('/fonts/DINCondensedBold.woff') format('woff'),
		url('/fonts/DINCondensedBold.ttf') format('truetype');
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'Clearface';
	src: url('/fonts/ITCClearfaceLTRegular.eot');
	src: url('/fonts/ITCClearfaceLTRegular.woff2') format('woff2'),
		url('/fonts/ITCClearfaceLTRegular.woff') format('woff'), url('/fonts/ITCClearfaceLTRegular.ttf') format('truetype');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

* {
	box-sizing: border-box;
}

html,
body,
#__next {
	height: 100%;
	width: 100%;
}

#__next {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

html {
	box-sizing: border-box;
	font-size: 100%;
	scroll-behavior: auto;
	word-spacing: 0.1rem;
}

body {
	font-family: 'Montserrat', Arial, sans-serif;
	cursor: default;
	-webkit-font-feature-settings: 'kern' 1, 'kern';
	font-feature-settings: 'kern' 1, 'kern';
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-feature-settings: 'kern';
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	margin: 0;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5;
	overflow-x: hidden;
	background-color: #0F0F0F;
	color: #fff;
}

#root {
	display: flex;
	flex-direction: column;
}

a {
	color: inherit;
	position: relative;
	display: inline-block;
	text-decoration: none;

	&:hover {
		color: #fff;

		&::after {
			transform: scaleX(1);
		}
	}

	&::after {
		border-bottom: 1px solid #fff;
		bottom: 0px;
		content: '';
		display: block;
		left: 0;
		position: absolute;
		transform: scaleX(0);
		transform-origin: left center;
		transition: transform 0.3s ease-out 0.1s;
		width: 100%;
	}
}

.inner {
	padding: 0;
	margin: 0 auto;
	max-width: 1440px;
	padding: 0 35px;
	width: 100%;
	z-index: 2;
	@media (min-width: 1440px) {
		padding: 0;
	}
}

#nprogress {
	pointer-events: none;
}

#nprogress .bar {
	background: #2dccd9;
	position: fixed;
	z-index: 1031;
	top: 0;
	left: 0;
	width: 100%;
	height: 2px;
}

#nprogress .peg {
	display: block;
	position: absolute;
	right: 0px;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px #2dccd9, 0 0 5px #2dccd9;
	opacity: 1;
	-webkit-transform: rotate(3deg) translate(0px, -4px);
	-ms-transform: rotate(3deg) translate(0px, -4px);
	transform: rotate(3deg) translate(0px, -4px);
}

h1 {
	//font-family: 'Clearface', 'Times New Roman';
	font-size: 26px;
	line-height: 1.12;
	letter-spacing: -1px;
	margin-bottom: 10px;

	@media screen and (min-width: 320px) {
		font-size: calc(26px + 10 * (100vw - 320px) / 1180);
	}
	@media screen and (min-width: 1500px) {
		font-size: 36px;
	}
	& font {
		font-family: 'Montserrat', Arial, sans-serif;
	}
}

h2,
h2 p {
	font-size: 22px;
	//font-family: 'Clearface', 'Times New Roman';
	line-height: 1.2;
	margin-bottom: 10px;
	@media screen and (min-width: 320px) {
		font-size: calc(22px + 10 * (100vw - 320px) / 1180);
	}
	@media screen and (min-width: 1180px) {
		font-size: 30px;
	}
}

h3,
h3 p {
	font-size: 20px;
	//font-family: 'Clearface', 'Times New Roman';
	line-height: 1.2;
	margin-bottom: 10px;
	@media screen and (min-width: 320px) {
		font-size: calc(20px + 10 * (100vw - 320px) / 1180);
	}
	@media screen and (min-width: 1180px) {
		font-size: 30px;
	}
}

h4,
h4 p {
	//font-family: 'Clearface', 'Times New Roman';
	font-size: 16px;
	line-height: 1.33;
	margin-bottom: 10px;

	@media screen and (min-width: 320px) {
		font-size: calc(16px + 10 * (100vw - 320px) / 1180);
	}

	@media screen and (min-width: 1180px) {
		font-size: 24px;
	}
}

h5,
h5 p {
	font-family: 'Montserrat', Arial, sans-serif;
	font-size: 14px;
	margin-bottom: 10px;
	@media (min-width: 600px) {
		font-size: 16px;
	}
}

h6,
h6 p {
	font-family: 'Montserrat', Arial, sans-serif;
	font-weight: 300;
	font-size: 14px;
	line-height: 25px;
	margin-bottom: 10px;
	@media screen and (min-width: 320px) {
		font-size: calc(14px + 2 * (100vw - 320px) / 1180);
	}
	@media screen and (min-width: 1180px) {
		font-size: 16px;
	}
}

p {
	margin: 0;
	padding: 0;

	font-size: 0.8rem;

	@media screen and (min-width: 320px) {
		font-size: calc(0.8rem + 4 * (100vw - 320px) / 1180);
	}

	@media screen and (min-width: 1180px) {
		font-size: 11px;
	}

	&:not(:last-child) {
		margin-bottom: 20px;
	}
}
